<template>
  <div>
    <search-order-fee-detail @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="download">下载</a-button>
      </a-space>
    </div>
    <a-row>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="订单总数(个)">
            {{ dataSum.order_count }}
          </a-descriptions-item>
          <a-descriptions-item label="殡仪服务订单(个)">
            {{ dataSum.service_order_count }}
          </a-descriptions-item>
          <a-descriptions-item label="附加产品订单(个)">
            {{ dataSum.sub_order_count }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="套餐金额(元)">
            {{ dataSum.package_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="厅房金额(元)">
            {{ dataSum.hall_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="其他项目金额(元)">
            {{ dataSum.other_fee }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="结算金额(元)">
            {{ dataSum.settle_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="已实际支付金额(元)">
            {{ dataSum.paid_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="优惠金额(元)">
            {{ dataSum.voucher_fee }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="6">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="剩余应付金额(元)">
            {{ dataSum.unpaid_fee }}
          </a-descriptions-item>
          <a-descriptions-item label="应退金额(元)">
            {{ dataSum.back_fee }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchOrderFeeDetail from '@/views/fee_data/order_fee_details/Search'
import { findOrderFeeDetails, findOrderFeeDownloadData } from '@/api/fee_detail'
import { downLoadExcel } from '@/utils/downLoadExcel'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'OrderFeeDetails',
  components: {
    Pagination,
    SearchOrderFeeDetail
  },
  data() {
    return {
      query: { effective: 'true' },
      data: [],
      loading: true,
      previewVisible: false,
      pagination: {
        total_count: 0
      },
      dataSum: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '订单号',
          dataIndex: 'order_no',
          width: 220,
          fixed: 'left'
        },
        {
          title: '订单类型',
          width: 150,
          dataIndex: 'order_type'
        },
        {
          title: '逝者姓名',
          width: 150,
          dataIndex: 'death_name'
        },
        {
          title: '进厅时间',
          width: 180,
          dataIndex: 'in_hall_time'
        },
        {
          title: '出厅时间',
          width: 180,
          dataIndex: 'out_hall_time'
        },
        {
          title: '订单状态',
          width: 150,
          dataIndex: 'order_status'
        },
        {
          title: '关联套餐',
          width: 150,
          dataIndex: 'package_name'
        },
        {
          title: '关联厅房',
          width: 150,
          dataIndex: 'hall_name'
        },
        {
          title: '套餐金额(元)',
          width: 130,
          dataIndex: 'package_fee',
          customRender: formatCurrency
        },
        {
          title: '厅房金额(元)',
          width: 130,
          dataIndex: 'hall_fee',
          customRender: formatCurrency
        },
        {
          title: '其他项目金额(元)',
          width: 150,
          dataIndex: 'other_item_fee',
          customRender: formatCurrency
        },
        {
          title: '结算金额(元)',
          width: 130,
          dataIndex: 'settle_fee',
          customRender: formatCurrency
        },
        {
          title: '已实际支付金额(元)',
          width: 180,
          dataIndex: 'paid_fee',
          customRender: formatCurrency
        },
        {
          title: '优惠金额(元)',
          width: 130,
          dataIndex: 'voucher_fee',
          customRender: formatCurrency
        },
        {
          title: '剩余应付金额(元)',
          width: 150,
          dataIndex: 'un_paid_fee',
          customRender: formatCurrency
        },
        {
          title: '应退金额(元)',
          width: 130,
          dataIndex: 'back_fee',
          customRender: formatCurrency
        },
        {
          title: '订单来源',
          width: 120,
          dataIndex: 'order_source'
        },
        {
          title: '申请人',
          width: 120,
          dataIndex: 'apply_name'
        },
        {
          title: '联系电话',
          width: 150,
          dataIndex: 'phone_number'
        },
        {
          title: '负责人',
          width: 120,
          dataIndex: 'person_name'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          width: 180
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      // 重置排序
      this.sort = this.$options.data().sort
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    download() {
      findOrderFeeDownloadData(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          const header = `订单号,订单类型,逝者姓名,进厅时间,出厅时间,订单状态,套餐金额（元）,厅房金额（元）,其他项目金额（元）,结算金额（元）,已实际支付金额（元）,优惠金额（元）,剩余应付金额（元）,应退金额（元）,订单来源,申请人,联系电话,负责人,创建时间,关联套餐,关联厅房\n`
          downLoadExcel(header, res.data, '订单费用明细')
        }
      })
    },

    fetchData() {
      this.loading = true
      findOrderFeeDetails(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data.detail
          this.pagination = res.pagination
          this.dataSum = res.data.sum
        }
        this.loading = false
      })
    }
  }
}
</script>
