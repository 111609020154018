export function downLoadExcel(header, data, fileName) {
  // 定义表头
  let str = header
  // 增加\t为了不让表格显示科学计数法或者其他格式
  for (let i = 0; i < data.length; i++) {
    for (const item in data[i]) {
      str += `${data[i][item] + '\t'},`
    }
    str += '\n'
  }
  // encodeURIComponent解决中文乱码
  const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
  // 通过创建a标签实现
  const link = document.createElement('a')
  link.href = uri
  // 对下载的文件命名
  link.download = `${fileName || '表格数据'}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
