import request from '@/utils/request'

// 查询订单费用明细
export function findOrderFeeDetails(params) {
  return request({
    url: '/fee_details/order',
    method: 'get',
    params: params
  })
}

// 查询订单费用明细
export function findOrderFeeDownloadData(params) {
  return request({
    url: '/fee_details/order/download',
    method: 'get',
    params: params
  })
}

// 查询厅房费用明细
export function findHallFeeDetails(params) {
  return request({
    url: '/fee_details/hall',
    method: 'get',
    params: params
  })
}

// 查询厅房下载数据
export function findHallFeeDownloadData(params) {
  return request({
    url: '/fee_details/hall/download',
    method: 'get',
    params: params
  })
}

// 查询微信小程序流水
export function findWechatFlows(params) {
  return request({
    url: '/fee_details/wechat_flows',
    method: 'get',
    params: params
  })
}

// 查询微信小程序流水下载数据
export function findWechatFlowDownloadData(params) {
  return request({
    url: '/fee_details/wechat_flows/download',
    method: 'get',
    params: params
  })
}
