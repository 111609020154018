<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6" :xxl="4">
          <a-form-item label="订单号">
            <a-input
              v-decorator="['order_no', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入订单号"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="申请人">
            <a-input
              v-decorator="['apply_name', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入申请人"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="联系电话">
            <a-input
              v-decorator="['phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入联系电话"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="订单类型">
            <a-select
              allow-clear
              @focus="fetchOrderTypeOptions"
              placeholder="请选择订单类型"
              v-decorator="['order_type']"
              @change="handleChangeType"
            >
              <a-select-option
                v-for="orderType in orderTypes"
                :key="orderType.value"
                :value="orderType.value"
              >
                {{ orderType.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="订单状态">
            <a-select
              placeholder="请选择订单状态"
              v-decorator="['order_status']"
              allow-clear
              @focus="fetchOrderStatusOptions"
            >
              <a-select-option
                v-for="orderStatus in orderStatusList"
                :key="orderStatus.value"
                :value="orderStatus.value"
              >
                {{ orderStatus.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="负责人">
            <a-select
              v-decorator="['person_id']"
              placeholder="请选择负责人"
              @focus="fetchUserOptions"
              @load="loadingUserOptions"
              allow-clear
            >
              <a-select-option
                v-for="user in userOptions"
                :key="user.id"
                :value="user.id"
              >
                {{ user.emp_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="关联套餐">
            <a-select
              v-decorator="['package_id']"
              placeholder="请选择关联套餐"
              @focus="fetchPackageOptions"
              @load="loadingPackageOptions"
              allow-clear
            >
              <a-select-option
                v-for="pack in packageOptions"
                :key="pack.id"
                :value="pack.id"
              >
                {{ pack.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :xxl="4">
          <a-form-item label="关联厅房">
            <a-select
              v-decorator="['hall_id']"
              placeholder="请选择关联厅房"
              @focus="fetchHallAllOptions"
              @load="loadingHallOptions"
              allow-clear
            >
              <a-select-option
                v-for="user in hallOptions"
                :key="user.id"
                :value="user.id"
              >
                {{ user.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" :xxl="8">
          <a-form-item label="创建时间">
            <a-range-picker
              style="width: 100%"
              :placeholder="['开始时间', '结束时间']"
              v-decorator="['created_at']"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="4">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findOrderTypeOptions, findOrderStatusOptions } from '@/api/order'
import { findTenantUserOptions } from '@/api/user'
import { formatRangeBeginDate, formatRangeBeginDateTime, formatRangeEndDate, formatRangeEndDateTime } from '@/utils/time'
import { findTenantPackageOptions } from '@/api/package_management'
import { findHallAllOptions } from '@/api/hall'

export default {
  name: 'SearchOrderFeeDetails',
  data() {
    return {
      orderTypes: [],
      orderStatusList: [],
      userOptions: [],
      hallOptions: [],
      packageOptions: [],
      loadingUserOptions: false,
      loadingHallOptions: false,
      loadingPackageOptions: false,
      form: this.$form.createForm(this, { name: 'order_fee_search' })
    }
  },
  created() {
  },
  methods: {
    handleChangeType(e) {
      this.form.setFieldsValue({
        order_status: ''
      })
      this.fetchOrderTypeOptions()
    },

    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantUserOptions().then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data
        }
        this.loadingUserOptions = false
      })
    },
    fetchHallAllOptions() {
      this.loadingHallOptions = true
      findHallAllOptions().then((res) => {
        if (res.code === 0) {
          this.hallOptions = res.data
        }
        this.loadingHallOptions = false
      })
    },
    fetchPackageOptions() {
      this.loadingPackageOptions = true
      findTenantPackageOptions().then((res) => {
        if (res.code === 0) {
          this.packageOptions = res.data
        }
        this.loadingPackageOptions = false
      })
    },
    fetchOrderTypeOptions() {
      findOrderTypeOptions().then((res) => {
        const date = []
        res.data.forEach(res => {
          if (res.value !== 'add_item') {
            date.push(res)
          }
        })
        this.orderTypes = date
      })
    },
    fetchOrderStatusOptions() {
      const fieldsValue = this.form.getFieldsValue()
      if (fieldsValue.order_type === undefined || fieldsValue.order_type === 0) {
        this.$warning({
          title: '请先选择订单类型！',
          content: ''
        })
      } else {
        findOrderStatusOptions({ order_type: fieldsValue.order_type }).then((res) => {
          this.orderStatusList = res.data
        })
      }
    },
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDateTime(fieldsValue.created_at),
        created_at_end: formatRangeEndDateTime(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
