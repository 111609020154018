import request from '@/utils/request'

// 查询套餐列表
export function findPackages(params) {
  return request({
    url: `/packages`,
    method: 'get',
    params: params
  })
}

// 查询套餐排序列表
export function findSortPackages() {
  return request({
    url: `/packages/sort`,
    method: 'get'
  })
}

// 查询套餐列表
export function findPackagesInfo(id) {
  return request({
    url: `/packages/${id}`,
    method: 'get'
  })
}

// 更新系统套餐
export function updateSystemPackage(id, data) {
  return request({
    url: `/system/packages/${id}`,
    method: 'put',
    data
  })
}

// 更新租户套餐
export function updateTenantPackage(id, data) {
  return request({
    url: `/tenant/packages/${id}`,
    method: 'put',
    data
  })
}

// 创建系统套餐
export function createSystemPackage(data) {
  return request({
    url: `/system/packages`,
    method: 'post',
    data
  })
}

// 创建租户套餐
export function createTenantPackage(data) {
  return request({
    url: `/tenant/packages`,
    method: 'post',
    data
  })
}

// 删除套餐
export function deletePackage(id) {
  return request({
    url: `/packages/${id}`,
    method: 'delete'
  })
}

// 更新系统套餐有效性
export function updateSystemPackageEffective(id, data) {
  return request({
    url: `/system/packages/${id}/effective`,
    method: 'patch',
    data
  })
}

// 更新租户套餐有效性
export function updateTenantPackageEffective(id, data) {
  return request({
    url: `/tenant/packages/${id}/effective`,
    method: 'put',
    data
  })
}

// 查询套餐外收费和佛事注意事项
export function findExtraContentInfoAndBuddhistNotesInfo(id) {
  return request({
    url: `/packages/${id}/extra_content_and_buddhist_notes`,
    method: 'get'
  })
}

// 更新家属通知书内的信息
export function updateExtraContentInfoAndBuddhistNotesInfo(id, data) {
  return request({
    url: `/packages/${id}/extra_content_and_buddhist_notes`,
    method: 'put',
    data
  })
}

export function findTenantPackageOptions() {
  return request({
    url: `/tenant/packages/options`,
    method: 'get'
  })
}

// 删除代理商套餐
export function deleteTenantPackage(id, data) {
  return request({
    url: `/tenant/packages/${id}`,
    method: 'delete',
    data
  })
}
